html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 10px;
	background-color: #212121 !important;
	font-family: 'Poppins', sans-serif;
	line-height: 1.5;
	min-height: 100vh;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
